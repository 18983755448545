.pageHeader.sticky {
  z-index: 4!important;
  .mainLogo {
    background-size: cover;
    background-position: top;
    height: 42px;
  }
  .mainCallToAction {
    margin: 0;
  }
}
@media screen and (min-width: $carousel-breakpoint){
  .carouselDetailWrapper {
    position: relative;
    padding-right: 0!important; // overwrite Fresco
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(-30%, -25%);
      height: 200%;
      aspect-ratio: 1 / 1;
      background-color: #23254f;
      border-radius: 100%;
      z-index: 1;
    }
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url($assets-path + "diagonal-line-reversed.png");
      background-repeat: no-repeat;
      background-position: left bottom -45px;
      background-size: 320px auto;
      z-index: 2;
    }
  }
  .carouselControls {
    align-items: center;
  }
}
.carouselSlideDetail {
  @media screen and (min-width: $carousel-breakpoint){
    background-color: transparent;
  }
  z-index: 3;
}

.homeFeedBox1 {
  background-color: $brand-beige;
}
.homeFeedBox2 {
  background-color: $brand-beige;
  padding-top: 0;
  overflow: auto; /* fixes top white margin on mob */
  .feedsTitle {
    display: none;
  }
}
.homeFeedBox3 {
}
.homeFeedBox4 {
  background-color: $brand-beige;
}
.ChallengeeventsFeed h3.feedsTitle {
//  display: none;
}
.homeFeedBox6 {
  padding-top: 0;
  .feedsTitle {
    display: none;
  }
}
.homeFeed {
  a.cta-button.view-all {
    margin: 1em auto;
    display: table;
    background-color: $brand-light-blue;
  }
}
@media screen and (min-width: map-get($breakpoints, sm)) {
  .feedList {
    display: flex;
    .feedItem {
      flex-direction: column;
    }
  }
}
.feedItem:only-child .feedItemDetailsWrapper {
  position: relative;
  .feedTitle, p {
    z-index: 3;
  }
  @media screen and (min-width: map-get($breakpoints, lg)) {
    padding-left: 0!important;
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(-10%, -25%);
      height: 200%;
      aspect-ratio: 1 / 1;
      background-color: $brand-secondary;
      border-radius: 100%;
      z-index: 1;
    }
  }
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url($assets-path + "diagonal-line.png");
    background-repeat: no-repeat;
    background-position: right bottom -45px;
    background-size: 320px auto;
    z-index: 2;
  }
  .readMore {
    background-color: $brand-primary;
    color: white;
    margin-top: 1em;
    display: table;
    &:hover {
      background-color: lighten($brand-primary, 10%)
    }
  }
}

@media screen and (min-width: map-get($breakpoints, lg)) {
  .homefeaturecategory-homeboximpactstats tr {
    padding: 0 50px;
  }
}


.homefeaturecategory-homeboxquickgiving {
  @media screen and (min-width: $container-max-width) {
    margin-top: -40px;
    border-radius: 5px;
  }
}
.homefeaturecategory-homeboxquickgiving .quickGivingPanel .formQuestion.donationAmount {
  border-radius: 50px;
  //aspect-ratio: 1 / 1;
  align-items: center;
  flex-direction: row;
  min-width: 95px;
  text-align: center;
  label {
    width: 100%;
  }
}
// Footer
.Footerlogos1, .Footerlogos2 {
  grid-column: 1 / 17;
  margin: 0!important;
}
.Footerlogos1 {
  background-color: $brand-light-blue;
}
.Footerlogos2 {
  background-color: $brand-pink;
}
.FooterLogosText {
  color: $brand-primary;
  padding: 0 20px;
  margin: 20px auto 0;
}
.FooterLogosImages {
  img {
    max-width: 180px;
  }
}
.footerBox[class*="ewsletter"] {
  grid-row: 3 / 3;
}
.Footercredits ul li {
  display: inline-flex;
  margin: 10px;
}
// mailchimp
#mc_embed_signup {
  max-width: 1200px;
  margin: auto;
  h4, p, ul {
    margin-bottom: .5em;
  }
	& > form {
		display: flex;
		flex-wrap: wrap;
		color: #fff;
	}

	.mc-field-group {
		text-align: center;
    width: 100%;
    label {
      margin-bottom: 0;
    }
    &:not(.input-group) {
      width: calc(33.3% - 20px);
      flex-direction: column;
      align-items: center;
      display: flex;
      margin: 10px;
      input {
        width: 100%;
      }
    }
	}

	ul {
		margin-top: 0.5rem;
		display: flex;
		justify-content: center;
		li {
			display: flex;
			align-items: center;
			margin-right: 1rem;
			margin-bottom: 0.5rem;
			input {
				margin-right: 5px;
			}

			label {
				font-size: 0.875rem;
			}
		}
	}

	.button {
		background-color: #fff;
		color: $brand-primary;
		font-size: 0.8rem;
	}

  @media screen and (max-width: 800px){
    .mc-field-group {
      width: 100%!important;
      margin: 0 20px 20px;
    }
  }

}

/* Faqs */
.accordianHeader:hover {
  background-color: $context-container-background-colour;
}

/* Post sidebar */

.content.postFeeds {
  display: flex;
  flex-direction: column;
}

/* Links in content */

.postContent a:not([class*='button']),
.listingIntro a:not([class*='button']),
.breadcrumbWrapper a  {
  color: #2f7ea7;
  text-decoration: underline;
}
.shareList a:not([class*='button']) {
  text-decoration: none;
}