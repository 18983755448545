//
// Home features
//

// Choose your overall home feature cards layout
.homeFeatures {
  @include cards-layout-prefab (
    $number-of-cards: 4,
    $prefab: 1
  );

  // Choose your card style for home feature cards
  .homeFeature {
    @include card(
      $card-hover-details-background-colour: $brand-pink
    );
    @include card-basic;
  }
}

// Alternatively, you can target specific features rather than styling them all
// If you do this, you will need to target them ALL uniquely, so:
// .homeBox1 {...}, .homeBox2 {...}, .homeBox3 {...} etc.


//
// Home feeds
//

// Choose your overall home feeds layout
@include home-feeds-prefab (
  $number-of-feeds: 3,
  $prefab: 1
);

// Specify a card layout for each individual feed

.homeFeedBox1 .feedList {
  @include cards-layout-prefab (
    $number-of-cards: 4,
    $prefab: 1
  );
  .feedItem {
    @include card (
      $card-details-background-colour: white,
      $card-hover-details-background-colour: white
    );
    @include card-basic ();
  }
}

.homeFeedBox2 .feedList {
  @include cards-layout-prefab (
    $number-of-cards: 3,
    $prefab: 1
  );
}

.homeFeedBox3 .feedList {
  @include cards-layout-prefab (
    $number-of-cards: 3,
    $prefab: 1
  );
}

.homeFeedBox4 .feedList {
  @include cards-layout-prefab (
    $number-of-cards: 3,
    $prefab: 1
  );
}

.homeFeedBox5 .feedList {
  @include cards-layout-prefab (
    $number-of-cards: 3,
    $prefab: 1
  );
}

.homeFeedBox6 .feedList {
  @include cards-layout-prefab (
    $number-of-cards: 3,
    $prefab: 1
  );
}

.feedItem {
  @include card ();
  @include card-basic ();
}

.feedItem:only-child{
  @include card(
    $card-details-background-colour: $brand-secondary,
    $card-hover-details-background-colour: darken($brand-secondary, 5%),
    $card-summary-colour: $brand-primary,
    $card-hover-summary-colour: $brand-primary,
    $card-details-padding: $spacer * 5,
    $card-hover-image-scale: 1.1,
   );
  @include card-side-by-side(
    $card-side-by-side-vertical-text-position: center,
    $card-side-by-side-image-width: 60%,
    $card-side-by-side-breakpoint: map-get($breakpoints, lg)
  );
}


//
// Listed posts
//

// Choose your card style for listed post cards
// (not products, search result or embedded map item)
.listedPost:not(.listedProduct):not(.listedSearchResult):not(.listedMapItem) {
  @include card;
  @include card-basic;
}


//
// Listed products
//

// If these are exactly the same as your regular 'listed posts' above
// you can remove the ':not(.listedProduct)' selector above and squash this
// together into more efficient CSS output
.listedProduct {
  @include card;
  @include card-basic;
}